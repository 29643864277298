import React from "react";
import Link from 'next/link';
import classNames from "classnames";
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const GridContainer = dynamic(() => import('/components/Grid/GridContainer.js'), { ssr: true });
const GridItem = dynamic(() => import('/components/Grid/GridItem.js'), { ssr: true });
const Button = dynamic(() => import('/components/CustomButtons/Button.js'), { ssr: true });
const Card = dynamic(() => import('/components/Card/Card.js'), { ssr: true });
const CardHeader = dynamic(() => import('/components/Card/CardHeader.js'), { ssr: true });
const CardBody = dynamic(() => import('/components/Card/CardBody.js'), { ssr: true });
const Paper = dynamic(() => import('@material-ui/core/Paper'), { ssr: true });

import productStyle from "/styles/jss/bbhost/pages/landingPageSections/productStyle.js";

const styles = {
    ...productStyle
};

const useStyles = makeStyles(styles);
import { trans, getLangFromObj } from "lang/lang.js";

export default function FeaturedProductsSection(props) {
  const classes = useStyles();
  const { locale, defaultLocale } = useRouter();
  const ColocationRoute = (locale === defaultLocale) ? "/colocation" : `/${locale}/colocation`;
  const BareMetalRoute = (locale === defaultLocale) ? "/baremetal" : `/${locale}/baremetal`;
  const VPSRoute = (locale === defaultLocale) ? "/vps" : `/${locale}/vps`;
  let products = <></>;
  
  products = props.products.map((product, key) => {
    if(typeof product.text === "object")
    {
        product.text = getLangFromObj(product.text, locale);
    }
    if(typeof product.subText === "object")
    {
        product.subText = getLangFromObj(product.subText, locale);
    }

    let iconColor = "info";
    let textColor = "white";

    if(product.spotlight)
    {
        iconColor = "danger";
    }

    let actionButton = (<></>);

    if(product.link.type === "external")
        actionButton = (
            <Button
                color={product.link.color}
                size="sm"
                href={product.link.href}
                target="_blank"
                rel="noopener noreferrer"
                round
                className={classNames(classes.actionbutton, "click_products_featured")}
                style={{fontSize:"0.775rem", width:"100%"}}
                data-price={product.price}
                data-item-id={product.text.replaceAll(" ", "_")} 
                data-item-name={product.text}
            >
                <FontAwesomeIcon icon={["fas", "cart-plus"]} size="lg" width={"20px"} /> {trans('products::buy', locale)}
            </Button>
        );
    else
        actionButton = (
            <Button
                color={product.link.color}
                size="sm"
                href={product.link.href}
                round
                className={classNames(classes.actionbutton, "click_products_featured")}
                style={{fontSize:"0.775rem", width:"100%"}}
                data-price={product.price}
                data-item-id={product.text.replaceAll(" ", "_")} 
                data-item-name={product.text}
            >
                <FontAwesomeIcon icon={["fas", "cart-plus"]} size="lg" width={"20px"} /> {trans('products::buy', locale)}
            </Button>
        );
    
    const configs = product.configs.map((config, key) => {
        return (
            <GridItem key={key} xs={12} sm={12} md={12}>
                <h5><FontAwesomeIcon icon={config.icon} size="sm" width={"18px"} /> <b>{trans(`products::tables.${config.name}`, locale)}</b>: {config.detail}</h5> 
            </GridItem>
        );
    });

    return (
        <GridItem key={key} xs={12} sm={12} md={3}>
            <Paper elevation={20} style={{ marginBottom: "15px" }}>  
                <Card plain className={classNames(classes.textCenter)}>
                    <CardHeader color={iconColor} style={{padding: "0.6rem 1px"}}>
                        <h4 className={classNames(classes.cardTitle)} style={{ color: textColor, fontSize: "1.0rem" }}>
                            {product.text}
                        </h4> 
                    </CardHeader>
                    <CardBody>
                        <GridContainer justifyContent="center" alignItems="center">
                            <GridItem xs={12} sm={12} md={12}>
                                <GridContainer justifyContent="center" alignItems="center">
                                    {configs}
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <GridContainer justifyContent="center" alignItems="center">
                                    <GridItem xs={12} sm={12} md={12}>
                                        <h5><FontAwesomeIcon icon={["fas", "money-bill-wave"]} size="lg" width={"20px"} /> <b>R$ {product.price}</b></h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        {actionButton}
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </Paper>
        </GridItem>
    );
  });
  
  return (
    <div className={classes.section}>
        <GridContainer justifyContent="center" alignItems="flex-end">
            {products}
        </GridContainer>
        <GridContainer justifyContent="center" alignItems="center">
            <GridItem xs={12} sm={12} md={4}>
                <Link href={VPSRoute} as={VPSRoute} passHref>
                    <Button color="info" block>
                        Ver todas as VPS
                    </Button>
                </Link>                    
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
                <Link href={BareMetalRoute} as={BareMetalRoute} passHref>
                    <Button color="info" block>
                        Ver todos os BareMetal
                    </Button>
                </Link>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
                <Link href={ColocationRoute} as={ColocationRoute} passHref>
                    <Button color="info" block>
                        Saber mais sobre o Colocation
                    </Button>
                </Link>
            </GridItem>
        </GridContainer> 
    </div>
  );
}
